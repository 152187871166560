import debug from 'debug'
const d = debug('cookie')

export const getAllCookieNames = () => {
  const cookies_parts = document.cookie.split(';')
  return cookies_parts.map(keyvalue => {
    const [name] = keyvalue.split('=')
    return name.trim()
  })
}

export const deleteCookie = (name, domain, path = '/') => {
  const domainPart = domain ? `;domain=${domain}` : ''
  const cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=${path}${domainPart}`
  document.cookie = cookie
  if (d.enabled) {
    d('deleteCookie -> ', cookie)
  }
}

export const deleteCookieOnAllDomains = (name, path = '/') => {
  const domain = location.host
  deleteCookie(name, domain, path) // remove cookie on fr.mappy.com
  deleteCookie(name, `.${domain}`, path) // remove cookie on .fr.mappy.com (yes, I’m speaking to you cto_bundle)
  deleteCookie(name, getMainCookieDomain(domain), path) // remove cookie on .mappy.com
}

export const getMainCookieDomain = hostname => hostname.substring(hostname.indexOf('.'))
