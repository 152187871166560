import { createSlice } from '@reduxjs/toolkit'
import debug from 'debug'
import { isEqual } from '../../utils/lang'

const d = debug('CMP')

const initialState = {}

const cmpSlice = createSlice({
  name: 'cmp',
  initialState,
  reducers: {
    registerConsent: (state, action) => {
      if (action.payload && !isEqual(state.consent, action.payload)) {
        if (d.enabled) d('registerConsent', JSON.stringify(action.payload))
        state.consent = action.payload
      }
    }
  }
})

export const { registerConsent } = cmpSlice.actions

export default cmpSlice
