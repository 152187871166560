import abtestTagsMiddlewares from './abtestTags.middlewares'
import actionToTagMiddlewares from './actionToTag.middlewares'
import geoentityBiTags from './geoentityBiTags.middlewares'
import multipathPublisherTagsMiddleware from './multipathPublisherTags.middlewares'
import pageTagsMiddlewares from './pageTags.middlewares'
import userPageTagsMiddlewares from './userPageTags.middlewares'

export default [
  ...pageTagsMiddlewares,
  ...actionToTagMiddlewares,
  ...userPageTagsMiddlewares,
  ...multipathPublisherTagsMiddleware,
  ...geoentityBiTags,
  ...abtestTagsMiddlewares
]
