import { computeBBoxFromCoordinates } from '../../domain/utils/map'
import geoLocationService from '../../geoLocationService'
import { findKey } from '../../utils/object'
import {
  selectGeolocationFetchOptions,
  selectGeolocationPosition,
  selectGeolocationRequestStatus,
  selectGeolocationStatus
} from '../geolocation/geolocation.selectors'
import {
  cleanGeolocationAfterFetch,
  setGeolocationPosition,
  setGeolocationStatus
} from '../geolocation/geolocationSlice'
import { requestMove } from './map.actions'
import {
  GEOLOC_MOVE_FROM_FOLLOW,
  GEOLOC_MOVE_FROM_INITIAL,
  GEOLOC_STATUS_ACTIVE,
  GEOLOC_STATUS_BLOCKED,
  GEOLOC_STATUS_DISABLED,
  GEOLOC_STATUS_FETCH_ACTIVE,
  GEOLOC_STATUS_FETCH_FOLLOW,
  GEOLOC_STATUS_FETCH_WORKFLOW,
  GEOLOC_STATUS_FOLLOW
} from './map.constants'
import { requestMapMove } from './mapSlice'

const BBOX_SIZE_IN_METER_AROUND_GEOLOC_POINT = 1000

const getPositionHandler = (dispatch, getState) => geoPosition => {
  dispatch(
    setGeolocationPosition({
      lat: geoPosition.coords.latitude,
      lng: geoPosition.coords.longitude
    })
  )
}

const getErrorHandler = (dispatch, getState) => error => {
  if (error.code === 1) {
    dispatch(setGeolocationStatus(GEOLOC_STATUS_BLOCKED))
    geoLocationService.clearWatch()
  }
  console.error('geoposition error', error)
}

export const geolocationWatcherMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    if (action.type === setGeolocationStatus.type) {
      const status = action.payload.status
      switch (status) {
        case GEOLOC_STATUS_FETCH_ACTIVE:
        case GEOLOC_STATUS_FETCH_FOLLOW:
          geoLocationService.watchPosition({
            positionHandler: getPositionHandler(dispatch, getState),
            errorHandler: getErrorHandler(dispatch, getState)
          })

          break
        case GEOLOC_STATUS_DISABLED:
          geoLocationService.clearWatch()
          break
      }
    }
    return r
  }

export const geolocationStatusMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const oldStatus = selectGeolocationStatus(getState())
    const r = next(action)

    switch (action.type) {
      case setGeolocationPosition.type:
        const newStatus = GEOLOC_STATUS_FETCH_WORKFLOW[oldStatus]
        if (newStatus) {
          const options = selectGeolocationFetchOptions(getState())
          dispatch(setGeolocationStatus(newStatus))
          dispatch(cleanGeolocationAfterFetch())
          if (options.mapMove) {
            const bbox = computeBBoxFromCoordinates(BBOX_SIZE_IN_METER_AROUND_GEOLOC_POINT)(action.payload)
            requestMove({ bbox, options: { from: GEOLOC_MOVE_FROM_INITIAL } })(dispatch, getState)
          }
        }
        break
      case requestMapMove.type:
        const from = action?.payload?.options?.from
        if (oldStatus === GEOLOC_STATUS_FOLLOW && ![GEOLOC_MOVE_FROM_FOLLOW, GEOLOC_MOVE_FROM_INITIAL].includes(from)) {
          dispatch(setGeolocationStatus(GEOLOC_STATUS_ACTIVE))
        }
        break
    }
    return r
  }

export const geolocationFollowMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const oldStatus = selectGeolocationStatus(getState())
    const r = next(action)

    switch (action.type) {
      case setGeolocationPosition.type:
        if (selectGeolocationStatus(getState()) === GEOLOC_STATUS_FOLLOW) {
          requestMove({ center: action.payload, options: { from: GEOLOC_MOVE_FROM_FOLLOW } })(dispatch, getState)
        }
        break
      case setGeolocationStatus.type:
        const status = action.payload.status
        const position = selectGeolocationPosition(getState())
        if (status === GEOLOC_STATUS_FOLLOW && oldStatus === GEOLOC_STATUS_ACTIVE && position) {
          const bbox = computeBBoxFromCoordinates(BBOX_SIZE_IN_METER_AROUND_GEOLOC_POINT)(position)
          requestMove({ bbox, options: { from: GEOLOC_MOVE_FROM_INITIAL } })(dispatch, getState)
        }
        break
    }
    return r
  }

export const processRequestStatusMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    const requestStatus = selectGeolocationRequestStatus(getState())
    if (requestStatus) {
      const fetchStatus = findKey(GEOLOC_STATUS_FETCH_WORKFLOW, value => value === requestStatus)
      const fetchOptions = selectGeolocationFetchOptions(getState())

      if (fetchStatus) {
        dispatch(cleanGeolocationAfterFetch())
        if (!fetchOptions.doNotRequest) {
          dispatch(setGeolocationStatus(fetchStatus, fetchOptions))
        }
      }
    }

    return r
  }

export default __SERVER__
  ? []
  : [
      geolocationWatcherMiddleware,
      geolocationStatusMiddleware,
      geolocationFollowMiddleware,
      processRequestStatusMiddleware
    ]
