const getFromStorage = storage => key => {
  if (__SERVER__) return undefined
  try {
    return storage.getItem(key)
  } catch (err) {
    console.error(err)
    return undefined
  }
}

const saveToStorage = storage => (key, value) => {
  if (__SERVER__) return undefined
  try {
    storage.setItem(key, value)
  } catch (err) {
    console.error(err)
  }
}

const loadJSONFromStorage = storage => key => {
  if (__SERVER__) return undefined
  try {
    const serializedState = storage.getItem(key)

    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (err) {
    console.error(err)
    return undefined
  }
}

const saveJSONInStorage = storage => (key, value) => {
  if (__SERVER__) return undefined
  try {
    const serializedValue = JSON.stringify(value)
    storage.setItem(key, serializedValue)
  } catch (err) {
    console.error(err)
  }
}

export const BROWSER_STORAGE_STORE_KEY = 'MAPPY_STORE'

export const BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION = 'allowDefaultUserPosition'
export const BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_TRUE = 'true'
export const BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_FALSE = 'false'

export const BROWSER_STORAGE_BOOKING_SEARCH_EXTENSION_KEY = 'bookingSearchExtensionLastDisplayed'

export const getFromLocalStorage = key => getFromStorage(localStorage)(key)

export const saveToLocalStorage = (key, value) => saveToStorage(localStorage)(key, value)

export const loadJSONFromLocalStorage = key => loadJSONFromStorage(localStorage)(key)

export const saveJSONInLocalStorage = (key, value) => saveJSONInStorage(localStorage)(key, value)

export const getFromSessionStorage = key => getFromStorage(sessionStorage)(key)

export const saveToSessionStorage = (key, value) => saveToStorage(sessionStorage)(key, value)

export const loadJSONFromSessionStorage = key => loadJSONFromStorage(sessionStorage)(key)

export const saveJSONInSessionStorage = (key, value) => saveJSONInStorage(sessionStorage)(key, value)

export const deleteEntryFromLocalStorage = key => localStorage.removeItem(key)
