import debug from 'debug'
import { getDefaults } from '../../parameters'
import { selectHasUserConsentedForTracking } from '../../store/cmp/cmp.selectors'
import { selectLocale } from '../../store/navigation/navigation.selectors'
import { hashCode } from '../../utils/string'
import { getMainCookieDomain } from '../utils/cookie'
import { OnceWithCondition } from '../utils/OnceWithCondition'

const d = debug('analytics')

global.ATInternet = global.ATInternet || {}
global.ATInternet.onTrackerLoad = onTrackerLoad

let tag

export const tagsBuffer = []

export const callAbTestOnceWithCondition = new OnceWithCondition()

export const _emptyCacheTagForTest = () => {
  tag = undefined
}

const sendBufferedTags = () => {
  if (!tagsBuffer.length) return

  if (d.enabled) d(`sending ${tagsBuffer.length} buffered tags`)

  while (tagsBuffer.length !== 0) {
    const pendingTagFn = tagsBuffer.shift()

    if (typeof pendingTagFn === 'function') pendingTagFn()
    else console.error('tagsBuffer entries should all be functions')
  }
}

export function onTrackerLoad() {
  sendBufferedTags()
}

const updateTrackingConsent = (trackingConsent, tag = getTag()) => {
  if (d.enabled) d(trackingConsent ? 'setVisitorOptin' : 'setVisitorOptout')

  if (trackingConsent) {
    tag.privacy.setVisitorOptin()
  } else {
    tag.privacy.setVisitorOptout()
  }
}

export const notifyUserTrackingConsentChange = trackingConsent => {
  updateTrackingConsent(trackingConsent)
}

export const getTag = () => {
  if (tag) return tag

  const getState = global.__mappy__store__rare_usage.getState
  const locale = selectLocale(getState())
  const trackingConsent = selectHasUserConsentedForTracking(getState())

  tag = new global.ATInternet.Tracker.Tag({
    secure: true,
    cookieDomain: getMainCookieDomain(location.host),
    site: getDefaults()[locale].xtsite
  })

  updateTrackingConsent(trackingConsent, tag)

  if (d.enabled) d('getTag', JSON.stringify(tag))

  return tag
}

const sendTag = type => info => {
  doAtInternetAction(tag => {
    tag[type].send(info)
  })
}

const doAtInternetAction = fn => {
  try {
    fn(getTag())
  } catch (err) {
    console.warn('doInternetAction error - buffer tag', err)
    tagsBuffer.push(() => {
      fn(getTag())
    })
  }
}

export const sendPageTag = info => {
  sendTag('page')(info)
  callAbTestOnceWithCondition.setCondition(true)
}
export const sendClickTag = sendTag('click')
export const sendPublisherTag = sendTag('publisher')

export const sendEventTag = (tagType, tagData) => {
  doAtInternetAction(tag => {
    tag.events.send(tagType, {
      tag_version: 'V3',
      ...tagData
    })
  })
}

const buildATAbTestString = (value, key) => `${Math.abs(hashCode(key || value))}[${value}]`

export const sendAbTestTags = (enrolledVariants = []) => {
  const tagFn = () => {
    enrolledVariants
      .filter(item => item?.id && item?.variant && item?.analytics)
      .forEach(({ id, variant }) => sendMvTestingTag(id, variant))
  }

  callAbTestOnceWithCondition.setFn(tagFn)
}

export const sendMvTestingTag = (id, variant) => {
  doAtInternetAction(tag => {
    const variantId = variant.id
    const variantData = variant.data
    const abTestVariant = {
      test: buildATAbTestString(id),
      waveId: 1,
      creation: buildATAbTestString(variantId, id + variantId)
    }
    tag.mvTesting.set(abTestVariant)
    if (variantData) {
      Object.keys(variantData).forEach(key => {
        const value = variantData[key]
        tag.mvTesting.add({
          variable: buildATAbTestString(key),
          version: buildATAbTestString(value, key + value)
        })
      })
    }
    tag.dispatch()
  })
}

export const setUserIdInTag = id => {
  doAtInternetAction(tag => {
    tag.identifiedVisitor.set({ id })
  })
}

export const unsetUserIdInTag = () => {
  doAtInternetAction(tag => {
    tag.identifiedVisitor.unset()
  })
}
