import { createSlice } from '@reduxjs/toolkit'
import { uniqBy } from '../../utils/array'
import { matches } from '../../utils/object'

export const initialState = {
  isWaitingForRequestedGeoentities: false,
  pois: [],
  currentPoiId: undefined,
  focusedPoiId: undefined,
  viewport: undefined,
  filters: undefined,
  extendBbox: false,
  filterParameters: undefined,
  fuels: undefined,
  visited: []
}

const setTabIdAndIdx = poi => {
  const currentBlocksIdx = 0
  const currentTabId = poi?.tabs?.[currentBlocksIdx]?.appId
  return { ...poi, currentTabId, currentBlocksIdx }
}

const applyToCurrentGeoentity = (state, fn) => {
  const { currentPoiId, pois } = state

  return pois.map(poi => {
    if (poi.id === currentPoiId) return fn(poi)
    return poi
  })
}

const geoentitySlice = createSlice({
  name: 'geoentity',
  initialState,
  reducers: {
    setGeoentitiesRequested: state => {
      state.isWaitingForRequestedGeoentities = true
    },
    setGeoentitiesReceived: state => {
      state.isWaitingForRequestedGeoentities = false
    },
    setSingleGeoentity: (state, action) => {
      const poi = action.payload
      const poiWithTabIdAndIdx = setTabIdAndIdx(poi)
      if ((state.pois || []).length > 0) {
        state.pois = state.pois.map(p => (p.id === poi.id ? poiWithTabIdAndIdx : p))
      } else {
        state.pois = [poiWithTabIdAndIdx]
      }
      state.currentPoiId = poi.id
      state.focusedPoiId = undefined
    },
    setGeoentityList: (state, action) => {
      const { pois, viewport, filters = [], extendBbox = 0 } = action.payload
      const currentFocusedPoiId = state?.focusedPoiId
      const focusedPoiId = currentFocusedPoiId ? pois.find(poi => poi?.id === currentFocusedPoiId)?.id : undefined

      state.focusedPoiId = focusedPoiId
      state.pois = pois
      state.viewport = viewport
      state.filters = filters
      state.extendBbox = Boolean(extendBbox)
    },
    addGeoentityList: (state, action) => {
      const { pois, viewport, filters = [] } = action.payload
      state.pois = uniqBy([...(state.pois || []), ...pois], 'id')
      state.viewport = viewport
      state.filters = filters
    },
    setGeoentityCurrentPoiId: (state, action) => {
      state.currentPoiId = action.payload
    },
    setGeoentityFocusedPoiId: (state, action) => {
      state.focusedPoiId = action.payload
    },
    refreshSelectedGeoentityData: (state, action) => {
      state.pois = applyToCurrentGeoentity(state, currentPoi => ({ ...currentPoi, ...setTabIdAndIdx(action.payload) }))
    },
    setCurrentGeoentityTabContent: (state, action) => {
      state.pois = applyToCurrentGeoentity(state, currentPoi => {
        const { tabs, currentTabId } = currentPoi
        const newTabs = tabs.map(tab => {
          const { appId, url } = tab
          if (appId !== currentTabId) return tab
          return {
            appId,
            url,
            content: action.payload
          }
        })
        return {
          ...currentPoi,
          tabs: newTabs
        }
      })
    },
    setGeoentityTabId: (state, action) => {
      const currentTabId = action.payload
      state.pois = applyToCurrentGeoentity(state, currentPoi => ({ ...currentPoi, currentTabId }))
    },
    setGeoentityTabBlocksIdx: (state, action) => {
      const currentBlocksIdx = action.payload
      state.pois = applyToCurrentGeoentity(state, currentPoi => ({ ...currentPoi, currentBlocksIdx }))
    },
    unselectGeoentity: state => {
      state.currentPoiId = undefined
    },
    requestUnfocusGeoentity: () => {
      /* for middleware purpose */
    },
    unfocusGeoentity: state => {
      state.focusedPoiId = undefined
    },
    setGeoentityFilterParameters: (state, action) => {
      state.filterParameters = {
        ...state.filterParameters,
        ...action.payload
      }
    },
    resetGeoentityFilterParameters: (state, action) => {
      state.filterParameters = undefined
    },
    resetGeoentityList: (state, action) => {
      if (!action.payload) {
        return { ...initialState, fuels: state.fuels, visited: state.visited }
      } else if (state?.pois?.length) {
        const filter = action?.payload?.filter ?? {}
        state.pois = state.pois.filter(poi => !matches(filter)(poi))
      }
    },
    setGeoentityNoResult: state => {
      return {
        error: 'geoentity.noresult',
        visited: state.visited
      }
    },
    setGeoentityNotFound: state => {
      return {
        error: 'geoentity.notfound',
        visited: state.visited
      }
    },
    setFuelInformation: (state, action) => {
      state.fuels = action.payload
    },
    addToVisited: (state, action) => {
      if (!state.visited.includes(action.payload)) {
        state.visited = [...state.visited, action.payload]
      }
    }
  }
})

export const {
  setGeoentitiesRequested,
  setGeoentitiesReceived,
  setSingleGeoentity,
  setGeoentityList,
  addGeoentityList,
  setGeoentityCurrentPoiId,
  setGeoentityFocusedPoiId,
  refreshSelectedGeoentityData,
  setCurrentGeoentityTabContent,
  setGeoentityTabId,
  setGeoentityTabBlocksIdx,
  unselectGeoentity,
  requestUnfocusGeoentity,
  unfocusGeoentity,
  setGeoentityFilterParameters,
  resetGeoentityFilterParameters,
  resetGeoentityList,
  setGeoentityNoResult,
  setGeoentityNotFound,
  setFuelInformation,
  addToVisited
} = geoentitySlice.actions

export default geoentitySlice
