import { createSlice } from '@reduxjs/toolkit'
import { isPE } from '../../domain/Device'
import { HEADER_DEFAULT_HEIGHT_GE, HEADER_DEFAULT_HEIGHT_PE } from '../../domain/header/header-constants'
import { OUTDOOR_STATUS_NONE } from '../../domain/outdoor/Utils'
import { USER_SCREENS } from './ui.constants'
import { GEOENTITY_DISPLAY_MODE_LIST, GEOENTITY_DISPLAY_MODE_MAP } from './ui.geoentities.constants'

export const initialState = {
  headerHeight: isPE() ? HEADER_DEFAULT_HEIGHT_PE : HEADER_DEFAULT_HEIGHT_GE,
  searchDialog: {
    isDisplayed: false
  },
  suggest: {
    isDisplayed: false
  },
  menuPE: {
    isDisplayed: false
  },
  map: {
    tooSlowTooltip: false,
    tooSlowTimestamp: 0
  },
  geoentity: {
    displayMode: GEOENTITY_DISPLAY_MODE_MAP,
    filters: {
      active: undefined
    }
  },
  itinerary: {
    elevationPointOver: null
  },
  itineraryOptionsDialog: {
    isDisplayed: false
  },
  itineraryTimeOptionsDialog: {
    isDisplayed: false
  },
  popin: {
    isDisplayed: false
  },
  outdoor: {
    status: OUTDOOR_STATUS_NONE
  },
  displayedModalIds: [],
  user: {
    isDisplayed: false,
    screen: {
      name: USER_SCREENS.default,
      params: null
    }
  },
  homeBanner: {
    shouldBeDisplayed: true
  },
  alertBanner: {
    isDisplayed: true
  }
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUiHeaderHeight: (state, action) => {
      state.headerHeight = action.payload
    },
    showUiElement: (state, action) => {
      state[action.payload] = {
        ...(state?.[action?.payload] ?? {}),
        isDisplayed: true
      }
    },
    hideUiElement: (state, action) => {
      state[action.payload] = {
        ...(state?.[action?.payload] ?? {}),
        isDisplayed: false
      }
    },
    displayUiMapTooSloowTooltip: state => {
      state.map.tooSlowTooltip = true
      state.map.tooSlowTimestamp = Date.now()
    },
    hideUiMapTooSlowTooltip: state => {
      state.map.tooSlowTooltip = false
    },
    displayUiOutdoor: (state, action) => {
      state.outdoor = action.payload
    },
    hideUiOutdoor: (state, action) => {
      state.outdoor = {
        status: OUTDOOR_STATUS_NONE
      }
    },
    displayUiModal: (state, action) => {
      state.displayedModalIds = [
        ...state.displayedModalIds.filter(id => id !== action.payload.modalId),
        action.payload.modalId
      ]
    },
    hideUiModal: (state, action) => {
      state.displayedModalIds = action.payload?.modalId
        ? state.displayedModalIds.filter(id => id !== action.payload.modalId)
        : []
    },
    resetUiGeoentityDisplayMode: state => {
      state.geoentity.displayMode = GEOENTITY_DISPLAY_MODE_MAP
    },
    toggleUiGeoentityDisplayMode: state => {
      state.geoentity.displayMode =
        state.geoentity.displayMode === GEOENTITY_DISPLAY_MODE_LIST
          ? GEOENTITY_DISPLAY_MODE_MAP
          : GEOENTITY_DISPLAY_MODE_LIST
    },
    setUiGeoentityDisplayMode: (state, action) => {
      state.geoentity.displayMode = action.payload
    },
    setUiGeoentityActiveFilter: (state, action) => {
      state.geoentity.filters = {
        active: action.payload.id
      }
    },
    resetUiGeoentityActiveFilter: (state, action) => {
      state.geoentity.filters = {
        active: undefined
      }
    },
    setUiItineraryElevationPointOver: (state, action) => {
      state.itinerary.elevationPointOver = action.payload
    },
    resetUiItineraryElevationPointOver: state => {
      state.itinerary.elevationPointOver = null
    },
    setUiUserScreen: (state, action) => {
      const { name, params } = action.payload
      state.user.screen = {
        name,
        params
      }
    },
    hideUiHomeBanner: (state, action) => {
      state.homeBanner = {
        shouldBeDisplayed: false
      }
    },
    hideAlertBanner: (state, action) => {
      state.alertBanner = {
        isDisplayed: false
      }
    }
  }
})

export const {
  setUiHeaderHeight,
  showUiElement,
  hideUiElement,
  displayUiMapTooSloowTooltip,
  hideUiMapTooSlowTooltip,
  displayUiOutdoor,
  hideUiOutdoor,
  displayUiModal,
  hideUiModal,
  toggleUiGeoentityDisplayMode,
  resetUiGeoentityDisplayMode,
  setUiGeoentityDisplayMode,
  setUiGeoentityActiveFilter,
  resetUiGeoentityActiveFilter,
  setUiItineraryElevationPointOver,
  resetUiItineraryElevationPointOver,
  setUiUserScreen,
  hideUiHomeBanner,
  hideAlertBanner
} = uiSlice.actions

export default uiSlice
