import { configureAndSendPageTag, configureAndSendPageTagV3 } from '../../domain/analytics/analytics.utils'
import { setGeoentityFilterParameters, setGeoentityTabId } from '../geoentity/geoentitySlice'
import { selectIsPreviousHistoryRoutePageless } from '../history/history.selectors'
import { setNavigationDone } from '../history/historySlice'

export const sendPageTagOnNavigationDone =
  ({ getState }) =>
  next =>
  action => {
    if (action.type === setNavigationDone.type) {
      configureAndSendPageTag(action, getState())
    }

    return next(action)
  }

export const sendPageTagOnGeoentityTabChange =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === setGeoentityTabId.type) configureAndSendPageTag(undefined, getState())

    return r
  }

export const sendPageTagOnGeoentityFilterChange =
  ({ getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === setGeoentityFilterParameters.type) {
      configureAndSendPageTag(undefined, getState())
    }
    return n
  }

export const sendPageTagV3OnNavigationDone =
  ({ getState }) =>
  next =>
  action => {
    const storeState = getState()
    if (action.type === setNavigationDone.type && !selectIsPreviousHistoryRoutePageless(storeState)) {
      try {
        configureAndSendPageTagV3(action, storeState)
      } catch (error) {
        console.error(error)
      }
    }

    return next(action)
  }

export default __SERVER__
  ? []
  : [
      sendPageTagOnNavigationDone,
      sendPageTagOnGeoentityTabChange,
      sendPageTagOnGeoentityFilterChange,
      sendPageTagV3OnNavigationDone
    ]
