import {
  addBreadcrumb,
  browserTracingIntegration,
  captureException,
  ErrorBoundary,
  init,
  setUser,
  withProfiler,
  withScope
} from '@sentry/react'
import debug from 'debug'
import { getDomain } from '../../parameters'
import { getMid } from '../analytics/BiTagger'

const d = debug('sentry')

/** Stationnary conditions */
const MAXIMUM_MONTHLY_AUDIENCE = 30_000_000
const SENTRY_ISSUE_QUOTA = 50_000
const SENTRY_PERF_QUOTA = 100_000

/** Hypothesis constants */
const PERCENTAGE_OF_AUDIENCE = 2 / 100
const OCCURENCE_OF_ERRORS_DURING_SESSION = 10
const TRANSACTION_PER_VISIT = 2
const PERF_TRACE_WEIGTH = 1.2

const SentryService = {
  userConsentedForSentry: false,
  dynamicIgnoreErrors: [],
  setDynamicIgnoreErrors: list => {
    SentryService.dynamicIgnoreErrors = list
  },
  shouldIgnoreException: (...args) => {
    try {
      const errorStr = args[0].toString()
      return SentryService.dynamicIgnoreErrors.some(filter => errorStr.includes(filter))
    } catch (e) {
      return false
    }
  },
  captureException: (...args) => {
    if (SentryService.shouldIgnoreException(...args)) {
      console.error('Sentry.captureException, exception ignored', ...args)
    } else if (SentryService.userConsentedForSentry) {
      if (d.enabled) d('Sentry.captureException', args)
      captureException(...args)
    } else {
      console.error('Sentry.captureException', ...args)
    }
  },
  addBreadcrumb: (category, message, level = 'info') => {
    if (SentryService.userConsentedForSentry) {
      if (d.enabled) d('Sentry.addBreadcrumb', category, message, level)
      addBreadcrumb({
        category,
        message,
        level
      })
    } else {
      console.info('Sentry.addBreadcrumb', category, message, level)
    }
  },
  withScope,
  withProfiler,
  ErrorBoundary
}

export const initSentry = sentryConsent => {
  SentryService.userConsentedForSentry = sentryConsent
  if (d.enabled) d('Consent for sentry', SentryService.userConsentedForSentry)
  if (!sentryConsent) return

  const errorSampleRate =
    SENTRY_ISSUE_QUOTA / (MAXIMUM_MONTHLY_AUDIENCE * OCCURENCE_OF_ERRORS_DURING_SESSION * PERCENTAGE_OF_AUDIENCE)

  const tracesSampleRate = SENTRY_PERF_QUOTA / (MAXIMUM_MONTHLY_AUDIENCE * TRANSACTION_PER_VISIT * PERF_TRACE_WEIGTH)

  if (d.enabled) {
    d('init - errorSampleRate: %s / tracesSampleRate: %s', errorSampleRate, tracesSampleRate)
  }

  init({
    dsn: 'https://dd315337fcb2408ab28f7c7e78d2511a@sentry.io/1320871',
    release: global.__APP_VERSION__,
    debug: false,
    integrations: [
      browserTracingIntegration({
        startTransactionOnPageLoad: true,
        startTransactionOnLocationChange: false,
        shouldCreateSpanForRequest: () => false
      })
    ],
    environment: getDomain(),
    allowUrls: [global?.location?.host],
    ignoreErrors: [
      "Can't execute code from a freed script", // Common error from IE (even if we’ve disabled it)
      'ResizeObserver loop limit exceeded', // Common error which seems ok to ignore
      'AbortController is not defined', // Error on old browser not supporting AbortController
      'Blocked a restricted frame with origin' // security error massively called...
    ],
    denyUrls: [/c\.amazon-adsystem\.com/i, /j\.adlooxtracking\.com\/ads/i],
    sampleRate: errorSampleRate,
    normalizeDepth: 5,
    tracesSampleRate
  })
  setUser({ id: getMid() })
}

export default SentryService
