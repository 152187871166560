import { POPUP_TYPE_ADDRESS } from '../../domain/map/popup/popup.selectors'
import { ROUTE_SEARCH_BY_APP, ROUTE_SEARCH_BY_RUBRIC, ROUTE_SEO_GEOENTITY } from '../../routes'
import { getRouteBbox } from '../../routes/search/search.route.utils'
import { once } from '../../utils/function'
import { loadAddressRequest } from '../address/addressSlice'
import { navigateBack, navigateToSearchByApp, navigateToSearchByRubric } from '../history/history.actions'
import {
  isPlacesRoute,
  selectCurrentHistoryRoute,
  selectCurrentHistoryRouteOptions
} from '../history/history.selectors'
import { reverseResolveRouteAndRegisterHistory } from '../history/historySlice'
import { requestMove } from '../map/map.actions'
import { displayMapPopup, mapUpdated } from '../map/mapSlice'
import { selectIsOnboardingContext } from '../navigation/navigation.selectors'
import { selectGeoentityTypeDataForSearch } from '../poiassets/poiassets.selectors'
import { refreshGeoentities } from '../search/search.actions'
import { UI_ELEMENTS } from '../ui/ui.constants'
import { isUIElementDisplayed } from '../ui/ui.selectors'
import { hideUiElement } from '../ui/uiSlice'
import { requestGeoentitiesForBI } from './geoentity.actions'
import {
  selectCurrentGeoentityId,
  selectOptionsForRequestMove,
  selectSelectedGeoentityLatLng
} from './geoentity.selectors'
import {
  addToVisited,
  requestUnfocusGeoentity,
  resetGeoentityList,
  setGeoentityFilterParameters,
  setGeoentityFocusedPoiId,
  setSingleGeoentity,
  unfocusGeoentity
} from './geoentitySlice'

export const handleMapPositioningOnGeoentitySelected =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    const placesRoute = isPlacesRoute(selectCurrentHistoryRoute(getState()))
    const actionIsSetSingleGeoentity = action.type === setSingleGeoentity.type

    if (placesRoute && actionIsSetSingleGeoentity) {
      const options = selectOptionsForRequestMove(getState())
      const center = selectSelectedGeoentityLatLng(getState())
      if (center && center.lat) requestMove({ center, options })(dispatch, getState)
    }

    return r
  }

export const handleDisplayingOfPopup =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    const placesRoute = isPlacesRoute(selectCurrentHistoryRoute(getState()))
    const actionIsHidePopin = action.type === hideUiElement.type && action.payload === UI_ELEMENTS.POPIN

    if (placesRoute && actionIsHidePopin) {
      dispatch(displayMapPopup(POPUP_TYPE_ADDRESS))
    }

    return r
  }

let unfocusGeoentityTimeout

export const planToUnfocusGeoentityIfNotRefocusedByPopin =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === requestUnfocusGeoentity.type) {
      clearTimeout(unfocusGeoentityTimeout)
      unfocusGeoentityTimeout = setTimeout(() => {
        dispatch(unfocusGeoentity())
      }, 500)
    }
    if (action.type === setGeoentityFocusedPoiId.type || action.type === setSingleGeoentity.type) {
      clearTimeout(unfocusGeoentityTimeout)
    }
    return next(action)
  }

export const resetGeoentitiesOnLoadAddressRequest =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === loadAddressRequest.type) {
      dispatch(resetGeoentityList())
    }
    return next(action)
  }

const requestGeoentitiesForBIOnce = once((id, dispatch, getState) => requestGeoentitiesForBI(id)(dispatch, getState))

export const requestGeoentitiesForBIOnBoarding =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const isSeoGeoentityRoute = selectCurrentHistoryRoute(getState()) === ROUTE_SEO_GEOENTITY
    if (isSeoGeoentityRoute && action.type === reverseResolveRouteAndRegisterHistory.type) {
      const id = selectCurrentGeoentityId(getState())
      requestGeoentitiesForBIOnce(id, dispatch, getState)
    }
    return next(action)
  }

export const refreshGeoentitiesOnFilterChange =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === setGeoentityFilterParameters.type) {
      const isPopin = isUIElementDisplayed(UI_ELEMENTS.POPIN)(getState())
      const bbox = isPopin ? getRouteBbox(getState()) : null
      refreshGeoentities(bbox)(dispatch, getState)
    }
    return n
  }

export const backOnFilterChange =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === setGeoentityFilterParameters.type) {
      const isPopin = isUIElementDisplayed(UI_ELEMENTS.POPIN)(getState())
      const isFuelCombobox = action.payload?.['combobox-fuel']
      if (isPopin && !isFuelCombobox) {
        navigateBack()(dispatch, getState)
      }
    }
    return n
  }

export const redirectFromSeoSearchPageOnMapUpdate =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)

    if (action.type === mapUpdated.type) {
      const isSearchByAppPage = ROUTE_SEARCH_BY_APP === selectCurrentHistoryRoute(getState())
      const isSearchByRubric = ROUTE_SEARCH_BY_RUBRIC === selectCurrentHistoryRoute(getState())
      const isOnboardingContext = selectIsOnboardingContext(getState())
      const bboxInHash = selectCurrentHistoryRouteOptions(getState())?.bbox
      if (isOnboardingContext && !bboxInHash) {
        const { appOrRubricIds } = selectGeoentityTypeDataForSearch(getState())
        if (isSearchByAppPage) {
          navigateToSearchByApp(appOrRubricIds, { avoidPageTag: true, doNotResetGeoentityDisplayList: true })(
            dispatch,
            getState
          )
        } else if (isSearchByRubric) {
          navigateToSearchByRubric(appOrRubricIds, { avoidPageTag: true, doNotResetGeoentityDisplayList: true })(
            dispatch,
            getState
          )
        }
      }
    }

    return n
  }

export const addCurrentPoiToVisited =
  ({ dispatch }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === setSingleGeoentity.type) {
      dispatch(addToVisited(action.payload.id))
    }
    return n
  }

export default [
  handleMapPositioningOnGeoentitySelected,
  handleDisplayingOfPopup,
  planToUnfocusGeoentityIfNotRefocusedByPopin,
  resetGeoentitiesOnLoadAddressRequest,
  requestGeoentitiesForBIOnBoarding,
  refreshGeoentitiesOnFilterChange,
  backOnFilterChange,
  redirectFromSeoSearchPageOnMapUpdate,
  addCurrentPoiToVisited
]
