export const DIDOMI_API_KEY = '003a8ab9-d71e-466e-8ba7-4a4e0ae36a46'

export const DIDOMI_NOTICE_ID = 'qdjf2zbA'

export const StandardPurposes = {
  Cookies: 'cookies',
  SelectBasicAds: 'select_basic_ads',
  CreateAdsProfile: 'create_ads_profile',
  SelectPersonalizedAds: 'select_personalized_ads',
  MeasureAdPerformance: 'measure_ad_performance'
  /* Unused (for now) purposes :
    MarketResearch: 'market_research',
    MeasureContentPerformance: 'measure_content_performance',
    ImproveProducts: 'improve_products',
    CreateContentProfile: 'create_content_profile',
    SelectPersonalizedContent: 'select_personalized_content',
    GeolocationData: 'geolocation_data',
    DeviceCharacteristics: 'device_characteristics'
    */
}

export const CustomPurposes = {
  PushNotifications: 'personnali-JGjwg3gy',
  Analytics: 'mesureda-b9AFF6XD',
  LiveRamp: 'personnali-n6LkBEKw',
  Utiq: 'utiq-iX4kPm2N',
  Booking: 'contextual-8Cng6EMQ'
}

export const CustomVendors = {
  Mappy: 'c:mappy-ZEsPmiLa',
  MappyNativeAds: 'c:mappyvde-yAgyyyBi',
  Utiq: 'c:utiq-Gx2xdQAa'
}

export const Vendors = {
  Criteo: 'criteo',
  GoogleAd: 'google',
  Sentry: 'sentry',
  Booking: 'Hyb3oS0az',
  AtInternet: 'atinterne-cWQKHeJZ',
  Piano: 'pianohybr-R3VKC2r4'
}
