import { getService } from '../../parameters'
import SERVICES from '../../services.constants'
import { selectAbTestVariantsIdsForTracking } from '../../store/abtest/abtest.selectors'
import { isGE } from '../Device'
import { paramsSerializerRepeat } from '../utils/url'
import { NA } from './analytics.constants'

export const SPD_PUSH_LINK = 'SPD_PUSH_LINK'
export const SPD_PUSH_DISPLAY = 'SPD_PUSH_DISPLAY'
export const SPD_PUSH_VDE = 'SPD_PUSH_VDE'
export const SPD_PUSH_EDITO = 'SPD_PUSH_EDITO'
export const SPD_TOOLTIP_GEOENTITY = 'SPD_TOOLTIP_GEOENTITY'
export const SPD_RESPONSE_APPLICATION = 'SPD_RESPONSE_APPLICATION'
export const SPD_RESPONSE_CATEGORY = 'SPD_RESPONSE_CATEGORY'
export const SPD_RESPONSE_SEARCH_ITINERARY = 'SPD_RESPONSE_SEARCH_ITINERARY'
export const SPD_RESPONSE_SEARCH = 'SPD_RESPONSE_SEARCH'
export const SPD_DETAIL_GEOENTITY = 'SPD_DETAIL_GEOENTITY'
export const SPD_DETAIL_GEOENTITY_CONTENT = 'SPD_DETAIL_GEOENTITY_CONTENT'
export const SPD_DETAIL_GEOENTITY_LIST = 'SPD_DETAIL_GEOENTITY_LIST'
export const SPD_GEOENTITY_GOTO = 'SPD_GEOENTITY_GOTO'
export const SPD_GEOENTITY_FANTOMAS = 'SPD_GEOENTITY_FANTOMAS'
export const SPD_GEOENTITY_PHONE = 'SPD_GEOENTITY_PHONE'
export const SPD_GEOENTITY_EMAIL = 'SPD_GEOENTITY_EMAIL'
export const SPD_GEOENTITY_WEBSITE = 'SPD_GEOENTITY_WEBSITE'
export const SPD_GEOENTITY_LINK = 'SPD_GEOENTITY_LINK'
export const SPD_GEOENTITY_LIST_LINK = 'SPD_GEOENTITY_LIST_LINK'
export const SPD_GEOENTITY_OUTDOOR = 'SPD_GEOENTITY_OUTDOOR'
export const SPD_DETAIL_GEOENTITY_CONTENT_RUBRIC = 'SPD_DETAIL_GEOENTITY_CONTENT_RUBRIC'

let mid
let mapSessionId

export const setMockValue = value => {
  mid = value
}

export const getMid = () => {
  if (!mid) mid = buildId()
  return mid
}

export const mockMapSessionId = value => {
  mapSessionId = value
}

export const getMapSessionId = () => {
  if (!mapSessionId) mapSessionId = buildId()
  return mapSessionId
}

export const buildId = () => String(Math.random()).substring(2, 14)

export const tagBi = (tagId, params) => {
  new Image().src = getBiTagUrl(tagId, params)
}

export const getTimestamp = () => {
  return Date.now()
}

export const getBiTagUrl = (tagId, params) =>
  getService(
    SERVICES.BUC,
    `/?${getTimestamp()}&${paramsSerializerRepeat(getBiTagParams(tagId))}&${paramsSerializerRepeat(params)}`
  ).url

const getAbTestsParam = () => {
  const storeState = global.__mappy__store__rare_usage?.getState?.()
  return (
    selectAbTestVariantsIdsForTracking(storeState)
      .map(({ id, variant }) => `${id}-${variant}`)
      .join(',') || NA
  )
}

export const getBiTagParams = tagid => {
  return Object.assign(
    {
      tagid
    },
    __BROWSER__
      ? {
          abtest: getAbTestsParam(),
          mid: getMid()
        }
      : {}
  )
}

export const getBIParams = (args = {}) =>
  Object.assign(
    {
      clientid: 'mappy'
    },
    __BROWSER__
      ? {
          mid: getMid(),
          screensize: isGE() ? 'GE' : 'PE',
          abtest: getAbTestsParam()
        }
      : {},
    args
  )

export const getBIParamsAsString = args => paramsSerializerRepeat(getBIParams(args))
