import { sendPublisherMultipathTag } from '../../domain/analytics/analytics.utils'
import { PUB_TAG } from '../../domain/analytics/PublisherTagsService'
import { selectItineraryState } from '../itinerary/itinerary.selectors'
import { getProviderMode } from '../itinerary/itinerary.utils'
import { setCurrentProvider, setItineraryIsComplete } from '../itinerary/itinerarySlice'
import { selectRoutesForMonomode } from '../itinerary/routes.selectors'
import { getMultipathPublisherPredefinedTagInfo } from './publisherTags.utils'

const getAllMultipathRoutePublisherTagInfosForImpression = storeState => {
  const currentRoutesByProvider = selectRoutesForMonomode(storeState)

  return Object.keys(currentRoutesByProvider).map((providerRoute, idx) => {
    const tag = idx === 0 ? PUB_TAG.PUB_MULTIPATH_RESULTAT_TOP : PUB_TAG.PUB_MULTIPATH_RESULTAT
    const provider = currentRoutesByProvider?.[providerRoute]?.[0]?.provider

    return getMultipathPublisherPredefinedTagInfo(
      {
        tag,
        provider
      },
      storeState
    )
  })
}

/**
 * This functions allows us to send all impression tags
 * PUB_MULTIPATH_RESULTAT_TOP, PUB_MULTIPATH_RESULTAT and PUB_MULTIPATH_RESULTAT in batch.
 *
 * Sending of single impression/click tag is handled in sendPublisherMultipathTag()
 */
const sendAllMultipathRoutePublisherImpressionTags = storeState => {
  const allTagInfosForImpression = getAllMultipathRoutePublisherTagInfosForImpression(storeState) || []

  allTagInfosForImpression.forEach(preparedTag =>
    sendPublisherMultipathTag({ isImpression: true, tagInfo: preparedTag }, storeState)
  )
}

export const multipathPublisherTagsMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const prevState = getState()
    const r = next(action)
    const storeState = getState()

    try {
      switch (action.type) {
        case setItineraryIsComplete.type: {
          sendAllMultipathRoutePublisherImpressionTags(storeState)

          break
        }
        case setCurrentProvider.type: {
          const prevMode = getProviderMode(selectItineraryState(prevState))
          const nextMode = getProviderMode(selectItineraryState(storeState))

          if (prevMode !== nextMode) {
            sendAllMultipathRoutePublisherImpressionTags(storeState)
          }

          break
        }
      }
    } catch (error) {
      console.error(error)
    }

    return r
  }

export default __SERVER__ ? [] : [multipathPublisherTagsMiddleware]
