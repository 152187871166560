import { SPD_DETAIL_GEOENTITY_CONTENT_RUBRIC, tagBi } from '../../domain/analytics/BiTagger'
import { selectCurrentGeoentityId, selectSelectedGeoentityBlocksContext } from '../geoentity/geoentity.selectors'
import { setGeoentityTabBlocksIdx } from '../geoentity/geoentitySlice'

export const geoentityAfterBlocksIdxChangeTagsMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)

    try {
      if (action.type === setGeoentityTabBlocksIdx.type) {
        const store = getState()
        const rubricid = selectSelectedGeoentityBlocksContext(store)
        const geoentityId = selectCurrentGeoentityId(store)
        tagBi(SPD_DETAIL_GEOENTITY_CONTENT_RUBRIC, { geoentity: geoentityId, rubricid })
      }
    } catch (error) {
      console.error(error)
    }

    return r
  }

export default __SERVER__ ? [] : [geoentityAfterBlocksIdxChangeTagsMiddleware]
