import debug from 'debug'
import { intersection } from '../../utils/array'
import { notifyUserTrackingConsentChange } from '../analytics/ATInternetService'
import { initSentry } from '../error/SentryService'
import { cleanCookiesAccordingConsent } from '../utils/cookieCleaner'
import { CustomPurposes, CustomVendors, StandardPurposes, Vendors } from './cmp.constants'

const d = debug('CMP')

export class CMPService {
  constructor(didomiSDK) {
    this.didomiSDK = didomiSDK
    this.onConsentCallbacks = []
  }

  get version() {
    return this.didomiSDK?.version
  }

  registerConsents(callback) {
    if (this.didomiSDK) {
      callback(this.getConsents())
    } else {
      this.onConsentCallbacks.push(callback)
    }
  }

  setSDK(didomiSDK) {
    this.didomiSDK = didomiSDK
    this.onConsentCallbacks.forEach(cb => cb(this.getConsents()))
  }

  isVendorEnabled(vendorId) {
    if (!this.didomiSDK) return false

    const enabledVendors = this.didomiSDK.getUserConsentStatusForAll().vendors.enabled || []
    return enabledVendors.includes(vendorId)
  }

  arePurposesEnabled(...purposesId) {
    if (!this.didomiSDK) return false

    const enabledPurposes = this.didomiSDK.getUserConsentStatusForAll().purposes.enabled || []
    return intersection(enabledPurposes, [...purposesId]).length === purposesId.length
  }

  hasUserConsentedForVendorAndItsPurpose(vendorId) {
    if (!this.didomiSDK) return false

    return this.didomiSDK.getCurrentUserStatus().vendors[vendorId]?.enabled ?? false
  }

  onConsentChanged() {
    const consent = this.getConsents()

    notifyUserTrackingConsentChange(consent.tracking)

    const consentByVendor = Object.values(Vendors).reduce((acc, id) => {
      acc[id] = this.hasUserConsentedForVendorAndItsPurpose(id)
      return acc
    }, {})

    cleanCookiesAccordingConsent(consentByVendor)

    initSentry(consent.sentry)
  }

  getConsents() {
    const c = {
      tracking:
        this.hasUserConsentedForVendorAndItsPurpose(Vendors.AtInternet) &&
        this.hasUserConsentedForVendorAndItsPurpose(Vendors.Piano),

      push_notifications:
        this.isVendorEnabled(CustomVendors.Mappy) && this.arePurposesEnabled(CustomPurposes.PushNotifications),

      native_advertising:
        this.isVendorEnabled(CustomVendors.MappyNativeAds) &&
        this.arePurposesEnabled(
          StandardPurposes.Cookies,
          StandardPurposes.MeasureAdPerformance,
          StandardPurposes.SelectBasicAds,
          StandardPurposes.SelectPersonalizedAds
        ),

      send_keywords: this.arePurposesEnabled(
        StandardPurposes.Cookies,
        StandardPurposes.CreateAdsProfile,
        StandardPurposes.SelectPersonalizedAds
      ),

      booking:
        this.arePurposesEnabled(CustomPurposes.Booking) &&
        this.isVendorEnabled(CustomVendors.Mappy) &&
        this.hasUserConsentedForVendorAndItsPurpose(Vendors.Booking),

      sentry: this.hasUserConsentedForVendorAndItsPurpose(Vendors.Sentry)
    }

    if (d.enabled) {
      d('getConsents:', c)
    }

    return c
  }

  open() {
    if (this.didomiSDK) {
      this.didomiSDK.preferences.show()
    }
  }

  getConsentString() {
    return new Promise(resolve => global.__tcfapi('getTCData', null, result => resolve(result.tcString)))
  }
}

export default new CMPService()
