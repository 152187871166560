import debug from 'debug'
import { uniq } from '../../utils/array'
import { deleteEntryFromLocalStorage } from '../../utils/browserStorageService'
import { Vendors } from '../cmp/cmp.constants'
import { deleteCookieOnAllDomains, getAllCookieNames } from './cookie'

const d = debug('cookie')

/**
 * Please keep in mind that the default cookies names can be changed:
 * https://developers.didomi.io/cmp/web-sdk/consent-notice/cookies#cookie-name
 */
export const COOKIE_TCF_NAME = 'euconsent-v2'
export const COOKIE_DIDOMI_NAME = 'didomi_token'
export const COOKIE_UTIQ_NAME = 'utiq_consent_status'

export const COOKIE_AT_USER_ID = 'atuserid'
export const COOKIE_AT_ID_VISITOR = 'atidvisitor'
export const COOKIE_CTO_BID_ID = 'cto_bidid'
export const COOKIE_CTO_BUNDLE = 'cto_bundle'
export const COOKIE_GOOGLE_EOI = '__eoi'
export const COOKIE_GOOGLE_GPI = '__gpi'
export const COOKIE_GOOGLE_ADS = '__gads'

const consentCookies = [COOKIE_DIDOMI_NAME, COOKIE_TCF_NAME, COOKIE_UTIQ_NAME]

export const cookiesByVendor = {
  [Vendors.AtInternet]: [COOKIE_AT_USER_ID, COOKIE_AT_ID_VISITOR],
  [Vendors.Piano]: [COOKIE_AT_USER_ID, COOKIE_AT_ID_VISITOR],
  [Vendors.Criteo]: [COOKIE_CTO_BID_ID, COOKIE_CTO_BUNDLE],
  [Vendors.GoogleAd]: [COOKIE_GOOGLE_EOI, COOKIE_GOOGLE_GPI, COOKIE_GOOGLE_ADS]
}

const getCookies = () => (getAllCookieNames() ?? []).filter(cookie => !consentCookies.includes(cookie))

export const cleanCookiesAccordingConsent = consentByVendor => {
  const notConsentedVendors = Object.entries(consentByVendor).reduce(
    (acc, [vendorId, consented]) => (!consented ? acc.concat(vendorId) : acc),
    []
  )
  if (d.enabled) {
    d('Existing vendors cookies:', getCookies())
  }

  const existingCookies = getCookies()
  const candidateToRemove = uniq(
    notConsentedVendors.flatMap(notConsentedVendor => cookiesByVendor[notConsentedVendor] ?? [])
  )

  const cookiesToRemove = candidateToRemove.filter(cookieName => existingCookies.includes(cookieName))

  cookiesToRemove.forEach(cookie => {
    if (d.enabled) {
      d(`consent refused, removing cookie '${cookie}'`)
    }
    deleteCookieOnAllDomains(cookie)
    deleteEntryFromLocalStorage(cookie)
  })

  if (d.enabled) {
    console.info('Remaining vendors cookies:', getCookies())
  }

  return cookiesToRemove.length
}
