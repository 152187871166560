import { configureStore } from '@reduxjs/toolkit'
import { flatten } from '../utils/array'
import abTestReducers from './abtest/abtestSlice'
import adMiddlewares from './ad/ad.middlewares'
import adReducers from './ad/adSlice'
import addressMiddlewares from './address/address.middlewares'
import addressReducers from './address/addressSlice'
import analyticsMiddlewares from './analytics/analytics.middlewares'
import cmpReducers from './cmp/cmpSlice'
import { getReduxDevToolsOptions } from './debug/reduxDevTools'
import sentryReduxEnhancer from './debug/sentryReduxEnhancer'
import geoentityMiddlewares from './geoentity/geoentity.middlewares'
import geoentityReducers from './geoentity/geoentitySlice'
import geolocationReducers from './geolocation/geolocationSlice'
import historyMiddlewares from './history/history.middlewares'
import historyReducers from './history/historySlice'
import incentiveMiddlewares from './incentive/incentive.middlewares'
import incentiveReducers from './incentive/incentiveSlice'
import geoentityOnItineraryMiddlewares from './itinerary/geoentity-on-itinerary.middlewares'
import itineraryMiddlewares from './itinerary/itinerary.middlewares'
import itineraryReducers from './itinerary/itinerarySlice'
import itineraryOptionsReducers from './itineraryOptions/itineraryOptionsSlice'
import mapMiddlewares from './map/map.middlewares'
import mapReducers from './map/mapSlice'
import navigationMiddlewares from './navigation/navigation.middlewares'
import navigationReducers from './navigation/navigationSlice'
import poiassetsReducers from './poiassets/poiassetsSlice'
import quizMiddlewares from './quiz/quiz.middlewares'
import quizReducers from './quiz/quizSlice'
import searchMiddlewares from './search/search.middlewares'
import searchHistoryMiddlewares from './search/searchHistory/searchHistory.middlewares'
import searchReducers from './search/searchSlice'
import seoReducers from './seo/seoSlice'
import suggestReducers from './suggest/suggestSlice'
import toolsMiddlewares from './tools/tools.middlewares'
import toolsReducers from './tools/toolsSlice'
import uiMiddlewares from './ui/ui.middlewares'
import uiReducers from './ui/uiSlice'
import userAccountMiddlewares from './userAccount/userAccount.middlewares'
import userAccountReducers from './userAccount/userAccountSlice'

const reducer = [
  abTestReducers,
  adReducers,
  addressReducers,
  suggestReducers,
  mapReducers,
  uiReducers,
  searchReducers,
  incentiveReducers,
  itineraryReducers,
  itineraryOptionsReducers,
  historyReducers,
  geoentityReducers,
  geolocationReducers,
  poiassetsReducers,
  cmpReducers,
  seoReducers,
  toolsReducers,
  userAccountReducers,
  quizReducers,
  navigationReducers
].reduce((acc, current) => {
  const { name, reducer } = current
  acc[name] = reducer
  return acc
}, {})

const createStore = ({ preloadedState, history } = {}) =>
  configureStore({
    preloadedState,
    reducer,
    middleware: () =>
      flatten([
        searchMiddlewares,
        searchHistoryMiddlewares,
        addressMiddlewares,
        geoentityMiddlewares,
        incentiveMiddlewares,
        itineraryMiddlewares,
        geoentityOnItineraryMiddlewares,
        historyMiddlewares, // FIXME MW-1520
        analyticsMiddlewares,
        uiMiddlewares,
        mapMiddlewares,
        toolsMiddlewares,
        adMiddlewares,
        userAccountMiddlewares,
        quizMiddlewares,
        navigationMiddlewares
      ]).map(middleware => store => middleware(store, history)),
    enhancers: getDefaultEnhancers =>
      __BROWSER__ ? getDefaultEnhancers().concat(sentryReduxEnhancer()) : getDefaultEnhancers(),
    devTools: getReduxDevToolsOptions()
  })

export default createStore
