import { generateFullRoute, isPagelessRoute, isRouteSkipableOnPop } from '../../domain/router/routeUtils'
import {
  ROUTE_ADDRESS,
  ROUTE_ADDRESS_HOME,
  ROUTE_BICYCLE_CITY,
  ROUTE_BICYCLE_HOME,
  ROUTE_GEOENTITY_AT_ADDRESS,
  ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
  ROUTE_HOME,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_HP_BY_MODE,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE,
  ROUTE_ITINERARY_ROADBOOK,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_QUIZ_CITIES,
  ROUTE_QUIZ_CITIES_DEPARTMENT,
  ROUTE_QUIZ_CITIES_REGION,
  ROUTE_QUIZ_HOME,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_QUERY,
  ROUTE_SEARCH_BY_RUBRIC,
  ROUTE_SEARCH_BY_SUBCATEGORY,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_GEOENTITY,
  ROUTE_TOOLS_COMPUTE_RADIUS,
  ROUTE_TRAFFIC_CITY,
  ROUTE_TRAFFIC_HOME,
  ROUTE_ZFE_CITY,
  ROUTE_ZFE_HOME
} from '../../routes'
import { getRouteBbox } from '../../routes/search/search.route.utils'
import { selectGeoentityRubricId } from '../geoentity/geoentity.selectors'
import { selectMapSearchBbox } from '../map/map.selectors'
import { isGeoentityOnSearchRoute, selectSearchRouteBaseParameters } from './history.selectors'

export const shouldReplace = (currentRoute, targetRoute, routeOptions = {}) => {
  const { forceReplaceForPop, forceNotReplace } = routeOptions

  if (forceReplaceForPop) return true

  if (forceNotReplace) return false

  if (isPagelessRoute(currentRoute)) return true

  if (currentRoute === targetRoute && currentRoute !== ROUTE_SEO_GEOENTITY && !isGeoentityOnSearchRoute(currentRoute))
    return true

  return isRouteSkipableOnPop(currentRoute)
}

export const getForcedPopRoute = (currentRoute, storeState) => {
  switch (currentRoute) {
    case ROUTE_BICYCLE_HOME:
    case ROUTE_ZFE_HOME:
    case ROUTE_TRAFFIC_HOME:
    case ROUTE_TRAFFIC_CITY:
    case ROUTE_BICYCLE_CITY:
    case ROUTE_ZFE_CITY:
    case ROUTE_ITINERARY_HOME:
    case ROUTE_ITINERARY_HP_BY_MODE:
    case ROUTE_SEO_ADDRESS:
    case ROUTE_ADDRESS:
    case ROUTE_ADDRESS_HOME:
    case ROUTE_SEARCH_BY_APP:
    case ROUTE_SEARCH_BY_CATEGORY:
    case ROUTE_SEARCH_BY_SUBCATEGORY:
    case ROUTE_SEARCH_BY_RUBRIC:
    case ROUTE_SEARCH_BY_QUERY:
      return {
        route: ROUTE_HOME
      }
    case ROUTE_TOOLS_COMPUTE_RADIUS:
    case ROUTE_QUIZ_HOME:
      return {
        route: ROUTE_HOME,
        routeOptions: {
          forceNotReplace: true
        }
      }
    case ROUTE_SEO_GEOENTITY:
      const rubricId = selectGeoentityRubricId(storeState)
      const bbox = selectMapSearchBbox(storeState)
      if (!rubricId) return { route: ROUTE_HOME }

      return {
        route: ROUTE_SEARCH_BY_RUBRIC,
        external: false,
        routeOptions: {
          rubric: rubricId,
          bbox,
          extendBbox: 0,
          forceNotReplace: true
        }
      }
    case ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY:
    case ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY:
    case ROUTE_GEOENTITY_ON_SEARCH_BY_APP:
    case ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC:
    case ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY:
      const params = selectSearchRouteBaseParameters(storeState)
      return {
        route: params.route,
        external: false,
        routeOptions: {
          ...params.routeOptions,
          bbox: getRouteBbox(storeState),
          extendBbox: 0,
          forceNotReplace: true,
          avoidSearching: true
        }
      }
    case ROUTE_ITINERARY_ROADBOOK:
      return {
        route: ROUTE_ITINERARY_RESULTS_BY_ROUTE,
        routeOptions: {
          avoidDispatchingAnyPageAction: true
        }
      }
    case ROUTE_GEOENTITY_AT_ADDRESS: {
      return {
        route: ROUTE_ADDRESS,
        routeOptions: {
          avoidDispatchingAnyPageAction: true
        }
      }
    }
    case ROUTE_ITINERARY_RESULTS_BY_ROUTE:
    case ROUTE_ITINERARY_RESULTS_BY_PROVIDER:
      return {
        route: ROUTE_ITINERARY_HOME
      }

    case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP:
      return { route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP }

    case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY:
      return { route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY }

    case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY:
      return { route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY }

    case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP:
    case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY:
    case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY:
      return { route: ROUTE_ITINERARY_RESULTS_BY_ROUTE }
    case ROUTE_QUIZ_CITIES:
    case ROUTE_QUIZ_CITIES_REGION:
    case ROUTE_QUIZ_CITIES_DEPARTMENT:
      return { route: generateFullRoute(ROUTE_QUIZ_HOME), external: true }
  }
}

const backCouples = [
  [ROUTE_SEO_GEOENTITY, ROUTE_ITINERARY_RESULTS_BY_ROUTE],
  [ROUTE_SEO_GEOENTITY, ROUTE_ITINERARY_ROADBOOK]
]

export const shouldNativePopForPreviousRoute = (currentRoute, prevRoute) => {
  if (!prevRoute) return false
  return Boolean(backCouples.find(couple => couple[0] === currentRoute && couple[1] === prevRoute))
}

export const shouldNativePop = (currentRoute, prevRoute, storeState) =>
  isPagelessRoute(currentRoute) ||
  shouldNativePopForPreviousRoute(currentRoute, prevRoute) ||
  !getForcedPopRoute(currentRoute, storeState)
