import { createCustomSelector } from '../../domain/utils/react-reselect'
import { useCallback, useRef } from 'react'
import { useSelector, useStore } from 'react-redux'

export const selectItemById = selector =>
  createCustomSelector(
    selector,
    (_, props) => props.id,
    (data, idToLookup) => data.find(({ id }) => id === idToLookup)
  )

export const useRefFromSelector = selector => {
  const ref = useRef()
  ref.current = useSelector(selector)
  return ref
}

export const useLiveSelector = selector => {
  const { getState } = useStore()

  return useCallback(() => selector(getState()), [])
}
